<!--
 * @Description: 选择微信小程序用户
 * @Author: 琢磨先生
 * @Date: 2023-01-18 15:10:19
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-22 12:08:47
-->
<template>
  <div @click="onOpen" style="line-height:0;">
    <slot>
      <el-button type="primary" link :icon="`${icon ? icon : 'Plus'}`">
        {{
          text? text: "选择用户"
        }}
      </el-button>
    </slot>
  </div>
  <!-- <div class="selection_wrp">
      <el-tag
        style="margin: 0 10px 10px 0"
        type="info"
        v-for="item in selection_list"
        :key="item.id"
      >{{ item.nick_name }} {{ item.mobile_phone }}</el-tag>
  </div>-->

  <el-dialog title="点击选择用户" v-model="visibleDialog" top="10vh" width="700px" draggable :destroy-on-close="true"
    append-to-body :close-on-click-modal="false" :close-on-press-escape="false">
    <div style="height: 35vh">
      <el-table ref="empTable" :data="member_list" row-key="id" @row-click="rowClick"
        style="height:100%;">
        <!-- <el-table-column type="selection" width="80" reserve-selection></el-table-column> -->
        <el-table-column label="姓名" prop="nick_name">
          <template #header>
            <el-input v-model="q" placeholder="手机号、昵称搜索" @input="inputChange"></el-input>
          </template>
          <template #default="scope">
            <div class="flex-row item-center">
              <el-avatar size="small" :src="scope.row.avatar_url" />
              <span style="margin-left:10px;">{{ scope.row.nick_name }} {{ scope.row.mobile_phone }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 20px">
      <el-alert type="info" :closable="false" title="已选择用户">
        <el-tag class="choose_member"  v-for="(item, i) in selection_nodes" :key="item.id" closable
          @close="onRemove(i)">
                <div class="flex-row item-center">
                  <el-avatar :size="25" :src="item.avatar_url"></el-avatar>
                  <span style="margin-left: 5px;">{{ item.nick_name }} {{ item.mobile_phone }}</span>
                </div>
          </el-tag>
      </el-alert>
    </div>
    <template #footer>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import member_api from "@/http/member_api";

export default {
  data() {
    return {
      visibleDialog: false,
      loading: false,
      q: "",
      member_list: [],
      //选中的人员
      selection_nodes: [],
    };
  },
  props: ["single", "icon", "title", "text", "list"],
  emits: ["success"],
  created() {
    this.loadData();
  },
  methods: {
    /**
     *
     */
    onOpen() {
      this.selection_nodes = [];
      this.visibleDialog = true;
      this.$nextTick(() => { 
        this.handlerDefaultSelection();
      });

    },

    /**
     * 处理默认选中
     */
    handlerDefaultSelection() {
      if (this.list && this.member_list.length > 0)
        this.list.forEach(item => {
          var node = this.member_list.find(o => o.id == item.id);
          if (!node) {
            node = Object.assign(item);
            this.member_list.push(node);
          }
          this.selection_nodes.push(node);
          // if (this.$refs.empTable) {
          //   this.$refs.empTable.toggleRowSelection(node, true);
          // }
        });
    },

    /**
     * 输入查询
     */
    inputChange() {
      this.loadData();
    },
    // /**
    //  * 选择更改
    //  */
    // selectionChange(nodes) {
    //   if (this.single && nodes.length > 1) {
    //     //单选
    //     var node = nodes[nodes.length - 1];
    //     this.$refs.empTable.clearSelection();
    //     this.$refs.empTable.toggleRowSelection(node, true);
    //     this.selection_nodes = [node];
    //     return;
    //   }
    //   this.selection_nodes = nodes;
    // },

    /**
     * 点击选择
     * @param {*} node 
     */
    rowClick(node){
      console.log(node);
      if(!this.selection_nodes.find(o=>o.id==node.id)){
        this.selection_nodes.push(node);
      }
    },

    /**
     * 移除选择
     */
    onRemove(i) {
      this.selection_nodes.splice(i, 1);
      // var node = this.selection_nodes.splice(i, 1)[0];
      // this.$refs.empTable.toggleRowSelection(node, false);
    },

    /**
     * 加载人员
     */
    loadData() {
      member_api.get_select(this.q).then((res) => {
        if (res.code == 0) {
          this.member_list = res.data;
          // this.handlerDefaultSelection();
        }
        this.loading = false;
      });
    },

    /**
     * 确定选择
     */
    onConfirm() {
      var list = this.selection_nodes.map((x) => Object.assign({}, x));
      this.$emit("success", list);
      this.visibleDialog = false;
    },
  },
};
</script>

<style  scoped>
.selection_wrp {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.choose_member{
  margin: 0 10px 10px 0;
  height: auto;
  padding:10px;
}

</style>
<!--
 * @Description: 新增、修改房东
 * @Author: 琢磨先生
 * @Date: 2022-05-24 09:40:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-01-18 15:51:52
-->


<template>
  <el-dialog v-model="dialogVisible" width="700px" :close-on-click-modal="false" :close-on-press-escape="false"
    :title="title" draggable @closed="onClosed">
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobile_phone">
        <el-col :span="10">
          <el-input v-model="form.mobile_phone" placeholder="手机号码"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="微信号" prop="wechat_no">
        <el-col :span="14">
          <el-input v-model="form.wechat_no" placeholder="微信号"></el-input>
        </el-col>
      </el-form-item>
      <!-- <el-form-item label="绑定用户">
        <el-select
          v-model="form.member_id"
          remote
          clearable
          filterable
          reserve-keyword
          placeholder="请输入昵称、手机号搜索"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in members"
            :key="item.id"
            :label="item.nick_name + item.mobile_phone"
            :value="item.id"
          >
            <div class="flex-row item-center">
              <el-avatar size="small" :src="item.avatar_url" />
              <span style="margin-left: 10px">{{ item.nick_name }}{{ item.mobile_phone }}</span>
            </div>
          </el-option>
        </el-select>
        <div class="help-block">
          <el-icon>
            <WarningFilled />
          </el-icon>搜索后需选择相应微信用户，确保房东微信用户已绑定手机号
        </div>
      </el-form-item>-->
      <el-form-item label="绑定用户">
        <div class="flex-column">
          <member-choose @success="memberSelectionChange" :list="selection_list"></member-choose>
          <div class="selection_wrp">
            <el-tag style="margin: 0 10px 10px 0" type="info" v-for="item in selection_list" :key="item.id">{{
              item.nick_name
            }} {{ item.mobile_phone }}</el-tag>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
      <!-- <el-button @click="cancelClick" :disabled="saving">取消</el-button> -->
    </template>
  </el-dialog>
</template>

<script>
import member_api from "@/http/member_api";
import MemberChoose from "../components/member_choose.vue";
export default {
  components: {
    MemberChoose,
  },
  data() {
    return {
      loading: false,
      saving: false,
      title: "",
      dialogVisible: false,
      form: {},
      members: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        mobile_phone: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          {
            pattern: /^1[23456789]\d{9}$/,
            message: "手机号格式错误",
            trigger: "blur",
          },
        ],
      },
      //搜索到的人员
      empOptions: [],
      //
      selection_list: [],
    };
  },
  props: ["item"],
  emits: ["close", "success"],
  watch: {
    item: {
      handler() {
        this.form = {};
        this.selection_list = [];

        if (this.item) {
          this.dialogVisible = true;
          if (this.item.id > 0) {
            this.title = "新增业主";
            this.form = Object.assign({}, this.item);
            if (this.item.member_list && this.item.member_list.length > 0) {
              this.selection_list = this.item.member_list;
            }
          } else {
            this.title = "新增业主";
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      if (this.selection_list && this.selection_list.length > 0) {
        this.form.member_ids = this.selection_list.map(x => x.id);
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/landlord/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.dialogVisible = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },

    /**
     * 搜索负责人
     */
    remoteMethod(q) {
      this.loading = true;
      member_api.get_select(q).then((res) => {
        if (res.code == 0) {
          this.members = res.data;
        }
        this.loading = false;
      });
    },

    /**
     *
     */
    onClosed() {
      this.$emit("close");
    },

    /**
     *
     */
    memberSelectionChange(list) {
      this.selection_list = list;
    },
  },
};
</script>

<style   scoped>

</style>

/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-24 20:06:41
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-24 20:07:31
 */
import http from "./axios_init";
export default {
  /**
   * 下拉搜索人员
   * @returns
   */
  get_select(q) {
    return http.get("/seller/v1/member/select?q=" + q ?? "");
  },
};
